class AppearGrid {
    constructor() {
        this.observers = [];
    }

    // Utility function to debounce a given function
    debounce(func, wait) {
        let timeout;
        return (...args) => {
            clearTimeout(timeout);
            timeout = setTimeout(() => func.apply(this, args), wait);
        };
    }

    // Function to initialise intersection observers and animations for each grid
    init() {
        this.setup();

        // Bind the debounce function to the instance
        this.debouncedResizeHandler = this.debounce(() => {
            // this.clear();
            this.setup();
        }, 200);

        window.addEventListener('resize', this.debouncedResizeHandler);
    }

    setup() {
        // Clear existing observers
        this.observers.forEach(observer => observer.disconnect());
        this.observers = [];

        // Select all grid containers
        const gridContainers = document.querySelectorAll('[data-appear-grid]');

        gridContainers.forEach(container => {
            // Select all grid items within the container
            const gridItems = container.querySelectorAll('.appear-item');

            // Create the Intersection Observer for this container
            const observer = new IntersectionObserver((entries, observer) => {
                entries.forEach(entry => {
                    if (entry.isIntersecting) {
                        entry.target.classList.add('_visible');
                        observer.unobserve(entry.target);
                    }
                });
            }, {
                threshold: 0.17
            });

            // Observe each grid item and apply staggered delay
            let currentRow = 0;
            let currentRowItems = [];
            let columnsPerRow = Math.floor(getComputedStyle(container).gridTemplateColumns.split(' ').length);

            gridItems.forEach((item, index) => {
                let row = Math.floor(index / columnsPerRow);

                if (row !== currentRow) {
                    currentRow = row;
                    currentRowItems = [];
                }

                item.style.transitionDelay = `${currentRowItems.length * 0.15}s`;
                currentRowItems.push(item);

                observer.observe(item);
            });

            // Store observer for future reference
            this.observers.push(observer);
        });
    }

    // Function to clear animations
    clear() {
        this.observers.forEach(observer => observer.disconnect());
        this.observers = [];
        const gridItems = document.querySelectorAll('.appear-item');

        gridItems.forEach(item => {
            item.classList.remove('_visible');
            item.style.transitionDelay = '';
        });
    }

    // Method to destroy event listeners and observers
    destroy() {
        window.removeEventListener('resize', this.debouncedResizeHandler);
        this.clear();
    }
}

export default new AppearGrid();
