// Imports
import gsap from 'gsap';

// Project imports
import utils from '@js/utils/utils';

class VideoAsset {
    constructor() {
    }
    init(callback) {
        const htmlElement = document.documentElement;

        // Detect if motion is allowed and the device battery is charged enough
        if (!window.SOMETHING.env.reducedMotion && utils.detectConnectionType() !== 'slow' && !utils.detectPerformanceBots()) {
            const videoEls = document.querySelectorAll('.asset-video');

            // If iOS Safari
            if (htmlElement.classList.contains('ios') && htmlElement.classList.contains('safari')) {
                videoEls.forEach((video) => {
                    this._show(video);
                });
            }
            else {
                // Any other browser
                videoEls.forEach((video) => {
                    // Test for support for readyState, else fallback to canplay event
                    if (video.readyState >= video.HAVE_FUTURE_DATA) {
                        // Show & play video
                        this._show(video);
                    } else {
                        video.addEventListener('canplay', () => {
                            // Show & play video
                            this._show(video);
                        }, false);
                    }
                });
            }
        }
    }
    _show(video) {
        // Check if the video is not already visible
        if (video.style.opacity !== '1') {
            // Get image element
            const image = video.parentElement.querySelector('img');

            // Show video
            gsap.to(video, {
                duration: 0.2,
                opacity: 1,
                display: 'block',
                onComplete: () => {
                    // If image, hide it
                    if (image) {
                        // Hide image
                        gsap.to(image, {
                            duration: 0.2,
                            opacity: 0,
                        });
                    }
                }
            });

            // Play video
            video.play();
        }
    }
}

export default new VideoAsset();
