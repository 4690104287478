// Imports
import LazyLoad from 'vanilla-lazyload';
import reframe from 'reframe.js';

// Project imports
import EndlessScroll from '@js/modules/EndlessScroll';
import Forms from '@js/modules/Forms';
import VideoAsset from '@js/modules/VideoAsset';

class Page {
    constructor() {

    }
    init() {
        // External links
        window.SOMETHING.modules.ExternalLinks.build();

        /**
         * Open external links in new tab
         */
        const extLinks = document.querySelectorAll('[data-external]');
        if (extLinks) {
            extLinks.forEach((item, i) => {
                item.addEventListener('click', (e) => {
                    let url = item.getAttribute('href');
                    window.open(url, '_blank').focus();
                });
            });
        }

        // Test for Formie
        if (window.Formie) {
            // Init forms so that all features are set up for the page
            window.Formie.initForms();
        }

        /**
         * Lazy load
         */
        let lazyLoadInstance = new LazyLoad({
            threshold: 400
        });

        /**
         * Reframe
         */
        reframe('.reframe');

        /**
         * Assets - Video
         */
        VideoAsset.init();

        /**
         * Endless scroll
         */
        EndlessScroll.init();

        /**
         * Forms
         */
        Forms.start();
    }
}

export default new Page();
